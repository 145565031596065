import { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import styles from "./quick_apply_flash.css";
import { links as iconLinks } from "../components/icons/base";
import { Body, Link } from "../components/typography";
import { IconButton } from "../components/icon_button";
import CloseIcon from "../components/icons/close";
import CheckIcon from "../components/icons/check";
import ErrorIcon from "../components/icons/error";
import PreviewIcon from "~/components/icons/preview";
import { useTranslation } from "react-i18next";

type QuickApplyFlashProps = {
  type: "success" | "error";
  closeFlash: () => void;
};

export const links = () => [...iconLinks(), { rel: "stylesheet", href: styles }];

export const QuickApplyFlash = ({ type, closeFlash }: QuickApplyFlashProps) => {
  const { t } = useTranslation("job_post");

  const [shouldRender, setShouldRender] = useState(false);
  const [jobSeekersUrl, setJobSeekersUrl] = useState("");

  useEffect(() => {
    setJobSeekersUrl(`${window.ENV.JOB_SEEKERS_URL}/profile`);
  }, []);

  useEffect(() => {
    setShouldRender(true);
  }, [setShouldRender]);

  if (!shouldRender) return null;

  let icon = <CheckIcon color="green" />;
  let message = t("application.quick_apply.success");

  if (type === "error") {
    icon = <ErrorIcon color="red" />;
    message = t("application.quick_apply.error");
  }

  const mountElement = window.document.getElementById("react-portal-mount-point");

  if (!mountElement) return null;

  return createPortal(
    <div className="quick-apply-flash">
      <span>{icon}</span>
      <div className="flash-body">
        <Body>{message}</Body>
        <div className="job-seekers-link">
          <Link href={jobSeekersUrl} target="_blank">
            {t("application.quick_apply.edit_profile")}
          </Link>
          <PreviewIcon color="green" size="xs" />
        </div>
      </div>
      <span>
        <IconButton
          label="Close flash"
          icon={CloseIcon}
          size="sm"
          onClick={closeFlash}>
        </IconButton>
      </span>
    </div>,
    mountElement
  );
};